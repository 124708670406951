/********************************************
* Header primary (incl. navigation)
********************************************/

function fnHeaderPrimary( i, oSection ) {

	var oBody 					= $( 'body' ),
		oHeader 				= $( oSection ),
		oScrollBar				= $( '.scroll', oSection );
		oMenuTrigger			= $( '.menu-trigger', oHeader ),
		iWindowPos				= $( window ).scrollTop();

	/*************************
	* Hide/show on scroll
	*************************/
    var bDidScroll,
		iLastScrollTop 			= 0,
    	iMinScroll 				= 10,
    	iNavbarHeight 			= oHeader.outerHeight();

    $( window ).scroll( function( event ) {
        bDidScroll = true;
    });

    setInterval( function() {
        if ( bDidScroll ) {
            hasScrolled();
            bDidScroll = false;
        }
    }, 250);

    function hasScrolled() {
        var iScrollTop = $(this).scrollTop();

        // Make sure they scroll more than iMinScroll
        if(Math.abs(iLastScrollTop - iScrollTop) <= iMinScroll)
            return;

        // Toggle `header-show` and `header-hide` classes on scroll down/up
        if ( iScrollTop > iLastScrollTop && iScrollTop > iNavbarHeight ) {
            // Scroll Down
            $( oHeader ).removeClass( 'header-show' ).addClass( 'header-hide' );
            $( oBody ).removeClass( 'header-show' ).addClass( 'header-hide' );
        } else {
            // Scroll Up
            if( iScrollTop + $(window).height() < $(document).height() ) {
                $( oHeader ).removeClass( 'header-hide' ).addClass( 'header-show' );
                $( oBody ).removeClass( 'header-hide' ).addClass( 'header-show' );
            }
        }

		// Apply `on-scroll` class after minimum scroll
        if ( iScrollTop > iLastScrollTop && iScrollTop > iMinScroll ) {
			$( oHeader ).addClass( 'on-scroll' );
			$( oBody ).addClass( 'on-scroll' );
        }

		// Remove `on-scroll` class after minimum scroll
        if ( iScrollTop <= iMinScroll ) {
            $( oHeader ).removeClass( 'on-scroll' );
            $( oBody ).removeClass( 'on-scroll' );
        }

        iLastScrollTop = iScrollTop;
    }


    /***************
	* Navigation open/close (mobile)
	***************/
    oMenuTrigger.on( 'click', function() {

        $( oBody ).toggleClass( 'nav-visible' );

    });

}



// END OF DOCUMENT
