/**************************************************************
* Responsive video loader
**************************************************************/

function fnResponsiveVideoLoading( i, oVideo ) {

	// Get objects and data
	var oVideo 				= $( oVideo ),
		sSD 				= oVideo.attr( 'data-src' ),
		sHD 				= oVideo.attr( 'data-hd-src' ),
		sULTRAHD 			= oVideo.attr( 'data-ultrahd-src' ),
		sVideoUrl 			= "",
		sScreenSize 		= $( window ).width();

	// Detect screen size
	if ( sScreenSize >= "1900" ) {
		sVideoUrl 			= sULTRAHD;
	} else if ( sScreenSize >= "1350" ) {
		sVideoUrl 			= sHD;
	} else {
		sVideoUrl 			= sSD;
	}

	// Add video URL
	oVideo.append( "<source src='" + sVideoUrl + "' type='video/mp4' >" );

}



// END OF DOCUMENT
