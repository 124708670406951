/**************************************************************
* Browser detection and output
**************************************************************/

function fnShowBrowserVersion() {

    var sDomainName         = window.location.hostname;

    // Restrict to non-production sites
    if ( sDomainName.search( ".local" ) >= 1 || sDomainName.search( ".flywheelsites" )  >= 1 ) {

        // Detect browser
        navigator.sayswho= (function(){
            var ua= navigator.userAgent, tem,
            M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if(/trident/i.test(M[1])){
                tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
                return 'IE '+(tem[1] || '');
            }
            if(M[1]=== 'Chrome'){
                tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
                if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
            M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
            if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
            return M.join(' ');
        })();

        // Output browser and version in Console
        console.log( "BROWSER : " + navigator.sayswho );

    }

}



// END OF DOCUMENT
