/********************************************
* Parallax (paroller.js)
********************************************/

function fnParallax( i, oElement ) {

	var oElement 			= $( oElement );

	// Initiate paroller with default config
	$( oElement ).paroller({
        factor:         -0.075,
        type:           'foreground',
        direction:      'vertical'
    });

}


// END OF DOCUMENT
