/**************************************************************
* Connection speed detection (limited support)
* Ref: https://wicg.github.io/netinfo/#dfn-effective-connection-type
* Ref: https://caniuse.com/#feat=netinfo
**************************************************************/

function fnConnectionSpeedClasses(){
	var sConnectionType = "connection-type-4g";
	if (typeof navigator.connection !== 'undefined') {

		var oNavigator = navigator.connection;
		if (typeof oNavigator.effectiveType !== 'undefined') {

			/*
			VALUES
				slow-2g
				2g
				3g
				4g
			 */
			$('body').addClass('connection-type-'+oNavigator.effectiveType);
		}else{
			$('body').addClass(sConnectionType);
		}
	}else{
		$('body').addClass(sConnectionType);
	}

}


// END OF DOCUMENT
