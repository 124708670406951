( function( $ ) {
    'use strict';


	/**
	* Initiate independent functions
	*/
	function fnInit() {

		var oBody 				= $( 'body' ),
		oHeader 				= $( 'header.primary' ),
		oDesktopMenu			= $( 'nav.desktop-menu', oHeader ),
		oDesktopMenuSub		 	= $( 'nav.sub-menu main.menu-area', oHeader ),
		oMobileMenu				= $( '.mobile-menu', oHeader ),
		oMenuTrigger			= $( '.mobile-nav-trigger', oHeader ),
		iWindowHeight			= $( window ).height(),
		iScrollThreshold		= 40,
     	iLastScrollTop 			= 0;



 	    /*****
	    	On page refresh make sure we are in the right location
	    */
   		setTimeout(pageLoadTop, 1000);
   		function pageLoadTop(){
   			if($('.ecom-outter-wrap').hasClass('steps-1')){
   				var iDistance = 0;
		    }else{
	   			var iDistance = $('.ecom-outter-wrap').offset().top - 100;
		    }

		    $('html, body').animate({
		        scrollTop: iDistance
		    }, 0);
   		}
	   
		   



		/***************
		* Scroll
		***************/
		$( window ).scroll( fnScroll );

	    function fnScroll() {

			var iCurrentScroll 		= $( this ).scrollTop();

			setTimeout( function() {

				// Scroll down (hide)
				if ( iCurrentScroll > iLastScrollTop ) {

					oHeader.addClass( 'header--hide' ).removeClass( 'header--show' );

				// Scroll up (show)
				} else {

					oHeader.addClass( 'header--show' ).removeClass( 'header--hide' );

				}

				// Pass threshold (fix)
				if ( iCurrentScroll > iScrollThreshold ) {

					oHeader.addClass( 'header--scroll' );

				// Below threshold (un-fix)
				} else {

					oHeader.removeClass( 'header--scroll' );

				}

				iLastScrollTop 		= iCurrentScroll;

			}, 100 );

		}



	    /***************
		* Desktop: Sub-menu tabs
		***************/
		// Store tab content as an object
		var oSubTabContentLocation			= $( 'nav.sub-menu-item-children', oDesktopMenuSub );

		// Update tab content on hover
		oDesktopMenuSub.find( 'nav.sub-menu-items li' ).hover( function() {

			fnUpdateSubMenuTab( $( this ) );

		});

		// Function to update tab
		function fnUpdateSubMenuTab( oTab ) {

			// Store new tab
			var oTab 				= $( oTab );

			// If tab has children, update the tab
			if ( oTab.hasClass( 'tab' ) ) {

				// Set new tab to current
				oTab.addClass( 'current' );

				// Store data from tab
				var sTabContent		= oTab.find( '.tab-content' ).html();

				// Update content with new tab data
				oTab.closest( oDesktopMenuSub ).find( 'nav.sub-menu-item-children' ).html( sTabContent );

				// Display tab content
				oTab.closest( oDesktopMenuSub ).find( 'nav.sub-menu-item-children' ).addClass( 'visible' );

			// If hover a link/tab with no children
			} else {

				// Hide & empty tab content
				oTab.closest( oDesktopMenuSub ).find( 'nav.sub-menu-item-children' ).removeClass( 'visible' );

			}

			// Un-set current from siblings
			oTab.siblings( 'li' ).removeClass( 'current' );

		}


	    /***************
		* Mobile: Navigation open/close
		***************/
	    oMenuTrigger.on( 'click', function() {

	        oHeader.toggleClass( 'mobile-menu-visible' );
	        $( this ).toggleClass( 'visible' );
	        oMobileMenu.toggleClass( 'visible' );

	    });


	    var sCurrentPaneClass				= 'current-pane',
		 	sParentPaneClass				= 'parent--pane',
		 	sActivePaneClass				= 'open',
		 	oPaneParent 					= $( 'li.' + sParentPaneClass, oMobileMenu ),
			oReturnButton					= $( '.return .button', oMobileMenu ),
			oBreadcrumb						= $( '.breadcrumb', oMobileMenu ),
		 	oCurrentPane;


	    /**
		* Open new pane (click on parent)
		*/
		oPaneParent.find( '> a' ).on( 'click', function( e ) {
			
			// Prevent default anchor behaviour
			e.preventDefault();
			
			// Update current pane
			oCurrentPane					= $( this ).parent( oPaneParent );
			oPaneParent.removeClass( sCurrentPaneClass );
			oCurrentPane.addClass( sCurrentPaneClass );
			
			// Set breadcrumb text (if pane / not accordions)
			fnUpdateBreadcrumb( $( this ).text() );

			// Open new pane
			oCurrentPane.addClass( sActivePaneClass ).siblings( 'li.' + sParentPaneClass ).removeClass( 'open' );

		});


		/**
		* Return (back to previous/parent pane)
		*/
		oReturnButton.on( 'click', function() {
			
			// Close current pane
			oCurrentPane.removeClass( sCurrentPaneClass + ' ' + sActivePaneClass );
			
			// Update current pane
			oCurrentPane					= oCurrentPane.closest( 'li.' + sParentPaneClass + '.' + sActivePaneClass );
			oCurrentPane.addClass( sCurrentPaneClass );
			
			// Update breadcrumb text
			fnUpdateBreadcrumb( oCurrentPane.find( '> a' ).text() );
			
		});


		/**
		* Pane functions
		*/

		/* Update breadcrumb text */
		function fnUpdateBreadcrumb( sText ) {
			
			if ( sText.length > 0 ) {
				
				oBreadcrumb.text( sText ).show();
				
			} else {
				
				oBreadcrumb.empty().hide();
				
			}
			
		}


		/******
		* Accordions
		******/

		var 	sParentAccordionClass		= 'parent--accordion',
			 	sActiveAccordionClass 		= 'open',
			 	oAccordionParent 			= $( 'li.' + sParentAccordionClass, oMobileMenu );

		/**
		* Open/close accordions
		*/
		oAccordionParent.find( '> a' ).on( 'click', function(e ) {
			
			// Prevent default anchor behaviour
			e.preventDefault();
			
			// Current accordion interaction
			var oThisAccordion				= $( this ).closest( oAccordionParent );
			
			// Check if accordion is already open
			if ( oThisAccordion.hasClass( sActiveAccordionClass ) ) {
				
				// Close accordion
				fnCloseAccordion( oThisAccordion )
				
			} else {
				
				// Open new accordion
				fnOpenAccordion( oThisAccordion )
				
				// Close sibling accordions
				fnCloseAccordion( oThisAccordion.siblings( 'li.' + sParentAccordionClass ) )
				
			}

		});


		/**
		* Accordion functions
		*/

		/* Open accordion */
		function fnOpenAccordion( oAccordion ) {
			
			oAccordion
				.addClass( sActiveAccordionClass )
					.find( '> ul' )
						.css( 'opacity', 0 )
						.slideDown( 'slow' )
						.animate(
							{ opacity: 1 },
							{ queue: false, duration: 'slow' }
						);
			
		}

		/* Close accordion */
		function fnCloseAccordion( oAccordion ) {
			
			oAccordion
				.removeClass( sActiveAccordionClass )
				.find( '> ul' )
					.slideUp( 'slow' )
					.animate(
						{ opacity: 0 },
						{ queue: false, duration: 'slow' }
					);
			
		}


		/**************
		* Mobile: Force menu pop-up to be 100vh (to get around Chrome/iOS bar)
		**************/
		$( window ).on( 'load', fnUpdatePopupHeight );
		$( window ).on( 'resize', fnUpdatePopupHeight );

		function fnUpdatePopupHeight() {

			// Update window height
			iWindowHeight			= $( window ).height();

			// Apply height to navigation pop-up
			oMobileMenu.css( 'height', iWindowHeight + 'px' );

		}

	}
    fnInit();

} ( jQuery ) );






function toggleTip(oObject){
	oObject.parentNode.classList.toggle("open");
}


function couponToggle(oObject){
	var oHeader = oObject.parentNode;
	oHeader.parentNode.classList.toggle("open");
}
