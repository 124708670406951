/**************************************************************
* Image lazy-loading (performance)
* Ref: https://github.com/verlok/lazyload
**************************************************************/

function fnImageLazyLoad( i, oSection ) {

	var myLazyLoad = new LazyLoad({
	    elements_selector: '.lazy',
	    callback_enter : function(el) {
	    	$(el).addClass('lazy-loaded');
	    }
	});

};



// END OF DOCUMENT
